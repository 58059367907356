import lozad from "lozad";
import { bindMicromodalEventTrigger } from "javascripts/micromodal";
import { swipeForWindows } from "javascripts/webpage/swipe-for-windows";
import { smoothScroll } from "javascripts/webpage/smooth-scroll";
import { headerScroll } from "javascripts/webpage/sp/header-scroll";
const planSelectionCancelPolicyName = "cancel-policy";
const planSelectionRateNameName = "rate-name";
const planSelectionPaymentmethodName = "paymentmethod";
const planSelectionBedGroupName = "bed-group";
const planSelectionSections = [planSelectionCancelPolicyName, planSelectionRateNameName, planSelectionPaymentmethodName, planSelectionBedGroupName];
const displayNoneClass = "u-display__none";

function showHide(element, condition) {
  if (condition) {
    element.classList.remove(displayNoneClass);
  } else {
    element.classList.add(displayNoneClass);
  }
}

function switchFloating(bedGroupPlan) {
  const plansSelectionModal = bedGroupPlan.closest(".js-plans-selection-modal");
  plansSelectionModal.querySelectorAll(".js-plans-selection-floating").forEach((item) => showHide(item, false));
  const floatingSelector = `.js-plans-selection-floating${dataSelector(bedGroupPlan, planSelectionSections)}`;
  showHide(plansSelectionModal.querySelector(floatingSelector), true);
}

function dataSelector(element, targets) {
  let selector = "";
  targets.forEach((target) => {
    const camelCaseTarget = target.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
    selector = selector + `[data-${target}-index="${element.dataset[camelCaseTarget + "Index"]}"]`;
  });
  return selector;
}

function updatePlanChoicesVisibility(checkedChoice, updateTarget) {
  const roomPlanSelectContainer = checkedChoice.closest(".js-plans-selection-container");
  // 一旦全て非表示
  roomPlanSelectContainer.querySelectorAll(`.js-${updateTarget}`).forEach((item) => showHide(item, false));
  const updateTargetSelector = `.js-${updateTarget}${dataSelector(checkedChoice, planSelectionSections.slice(0, planSelectionSections.indexOf(updateTarget)))}`;
  const updateTargetElements = roomPlanSelectContainer.querySelectorAll(updateTargetSelector);
  // グループに紐づく選択肢のみ表示
  updateTargetElements.forEach((item) => showHide(item, true));
  // ベッドオプションが1つのみの場合は非表示
  if (updateTarget == planSelectionBedGroupName) {
    showHide(roomPlanSelectContainer.querySelector(".js-bed-groups-container"), updateTargetElements.length >= 2);
  }
  return roomPlanSelectContainer.querySelector(`${updateTargetSelector} :checked`).closest(`.js-${updateTarget}`);
}

function bindPlanSelectionModalTrigger() {
  // キャンセルポリシー
  document.querySelectorAll(".js-cancel-policy").forEach((cancelPolicy) => {
    cancelPolicy.addEventListener("click", (event) => {
      const checkedRateName = updatePlanChoicesVisibility(cancelPolicy, planSelectionRateNameName);
      const checkedPaymentmethod = updatePlanChoicesVisibility(checkedRateName, planSelectionPaymentmethodName);
      const checkedBedGroup = updatePlanChoicesVisibility(checkedPaymentmethod, planSelectionBedGroupName);
      switchFloating(checkedBedGroup);

      const roomPlanSelectContainer = cancelPolicy.closest(".js-plans-selection-container");
      roomPlanSelectContainer.querySelector(".js-cancel-policy-detail.is-open").classList.remove("is-open");
      event.currentTarget.querySelector(".js-cancel-policy-detail").classList.add("is-open");
    });
  });

  // 追加オプション
  document.querySelectorAll(".js-rate-name").forEach((rateName) => {
    rateName.addEventListener("click", () => {
      const checkedPaymentmethod = updatePlanChoicesVisibility(rateName, planSelectionPaymentmethodName);
      const checkedBedGroup = updatePlanChoicesVisibility(checkedPaymentmethod, planSelectionBedGroupName);
      switchFloating(checkedBedGroup);
    });
  });

  // 支払い方法
  document.querySelectorAll(".js-paymentmethod").forEach((paymentmethod) => {
    paymentmethod.addEventListener("click", () => {
      const checkedBedGroup = updatePlanChoicesVisibility(paymentmethod, planSelectionBedGroupName);
      switchFloating(checkedBedGroup);
    });
  });

  // ベッドオプション
  document.querySelectorAll(".js-bed-group").forEach((bedGroup) => {
    bedGroup.addEventListener("click", () => {
      switchFloating(bedGroup);
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  function pollHotels() {
    const loader = document.querySelector(".js-loader");
    fetch(document.querySelector(".js-polling-url").dataset.pollingUrl)
      .then((response) => {
        if (!response.ok) {
          throw "ErrorException";
        }
        return response.json();
      })
      .then((data) => {
        if (data.html) {
          loader.style.display = "none";
          searchRoomPlans.innerHTML = data.html;
          const addedElements = searchRoomPlans.querySelectorAll(".js-lazy:not([data-loaded])");
          const newObserver = lozad(addedElements, {
            rootMargin: "200% 400%",
          });
          newObserver.observe();
          bindMicromodalEventTrigger(searchRoomPlans);

          bindPlanSelectionModalTrigger();
          swipeForWindows();
          smoothScroll();
          headerScroll();
          setRoomId();
          scrollToRoom();
        } else {
          setTimeout(pollHotels, 1000);
        }
      })
      .catch(() => {
        loader.style.display = "none";
        document.querySelector(".js-search-room-plans").innerHTML = `
          <div class="c-no-results">
            <h3 class="c-no-results__heading">サーバーエラー</h3>
            <p class="c-no-results__description">
              ただいまサイトへのアクセスが大変混み合っております。
              <br>
              しばらくたってから再度ご利用ください。
            </p>
          </div>`;
      });
  }
  const searchRoomPlans = document.querySelector(".js-search-room-plans");
  if (searchRoomPlans) {
    searchRoomPlans.innerHTML += '<div class="p-hotels-shared-loader js-loader">プランを取得中です</div>';

    pollHotels();
  }

  function setRoomId() {
    document.querySelectorAll(".js-open-calendar").forEach((element) => {
      element.addEventListener("click", () => {
        document.querySelector(".js-search-room-id-input").value = element.dataset.roomId ? element.dataset.roomId : null;
      });
    });
  }

  function scrollToRoom() {
    const roomId = document.querySelector(".js-search-room-id-input").value;
    const selectedRoom = document.getElementById(roomId);
    if (!selectedRoom) {
      return;
    }
    if (selectedRoom.classList.contains("js-details-room")) {
      document.querySelector(".js-details-room-summary").click();
    }
    selectedRoom.scrollIntoView({ block: "center" });
    const openPlanModalButton = selectedRoom.getElementsByClassName("js-open-plan-modal")[0];
    if (openPlanModalButton) {
      openPlanModalButton.click();
    }
  }
});
