document.addEventListener("DOMContentLoaded", () => {
  const removeClass = setInterval(() => {
    const billboardStickyClass = "c-ads-box--1x1";
    const billboardSticky = document.querySelector(`.${billboardStickyClass}`);
    if (billboardSticky) {
      billboardSticky.classList.remove(billboardStickyClass);
      clearInterval(removeClass);
    }
  }, 10000);
});
