import JoinMember from "javascripts/webpage/join-member";

document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelectorAll("#js-aggregated_hotels-show", "#js-hotels.hotels-show")) {
    return;
  }
  JoinMember();
  // カバー
  $(".js-slick-cover-image").slick({
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    cssEase: "linear",
    dots: false,
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    zIndex: 1,
  });
  // ホテルパーツのもっと見るから遷移した場合に開いた状態にする
  if (window.location.hash === "#target-section-booking") {
    const bookingOpen = document.querySelector(".js-booking-open");
    if (bookingOpen != null) {
      bookingOpen.click();
    }
  }
  // 口コミパーツをもっと見る
  $(".js-more-button").on("click", function () {
    $(this).hide();
    $(this).parent(".js-review-more").removeClass("p-hotels-show-review--hide");
  });
  // Floorguide： ページ遷移後にViewport内に `target-current` を表示
  const currentPosition = document.getElementById("target-current");
  if (currentPosition) {
    currentPosition.scrollIntoView({
      block: "end",
      inline: "end",
    });
  }
});
