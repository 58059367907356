import { micromodal } from "javascripts/micromodal";

document.addEventListener("DOMContentLoaded", () => {
  const sendPinCodeForm = document.getElementById("js-send-pin-code-form");
  const emailInput = document.getElementById("js-send-pin-code-email");
  const verifyPinCodeform = document.getElementById("js-verify-pin-code-form");
  const pinCodeInputs = document.querySelectorAll(".js-pin-code-input");
  const message = document.querySelector(".js-verify-pin-code-response-message");
  const verifyErrorMessageClass = "p-users-new__message--error";
  const verifySuccessMessageClass = "p-users-new__message--success";
  let isVerifiedPinCode = false;
  const uidInput = document.getElementById("js-user-uid");
  const passwordInput = document.getElementById("js-user-password");
  const passwordConfirmationInput = document.getElementById("js-user-password-confirmation");
  const joinButton = document.getElementById("js-join-button");

  function clearVerifyMessage() {
    message.textContent = "";
    message.classList.remove(verifySuccessMessageClass, verifyErrorMessageClass);
    isVerifiedPinCode = false;
  }

  function clearPinCodeInputs() {
    pinCodeInputs.forEach((input) => (input.value = ""));
    pinCodeInputs[0].focus();
  }

  function validatePassword(passwordField) {
    const password = passwordField.value;
    if (8 <= password.length && password.length <= 20) {
      const hasDigit = /\d/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasUppercase = /[A-Z]/.test(password);
      const hasOthers = /[^a-zA-Z\d]/.test(password);
      if ([hasDigit, hasLowercase, hasUppercase, hasOthers].filter(Boolean).length >= 2) {
        passwordField.setCustomValidity("");
        return true;
      } else {
        passwordField.setCustomValidity("英小文字・英大文字・数字・記号のいずれか2種類以上を含めてください");
        return false;
      }
    } else {
      passwordField.setCustomValidity("");
      return false;
    }
  }

  function validatePasswordConfirmation(passwordField, passwordConfirmationField) {
    if (passwordField.value !== "" && passwordField.value === passwordConfirmationField.value) {
      passwordConfirmationField.setCustomValidity("");
      return true;
    } else {
      passwordConfirmationField.setCustomValidity("パスワードが一致していません");
      return false;
    }
  }

  function validateSamePassword() {
    if (passwordInput) {
      return validatePassword(passwordInput) && validatePasswordConfirmation(passwordInput, passwordConfirmationInput);
    } else {
      return true;
    }
  }

  function activateJoinButton() {
    if (isVerifiedPinCode && uidInput.value !== "") {
      if (passwordInput) {
        if (passwordInput.value !== "" && passwordConfirmationInput.value !== "") {
          joinButton.disabled = false;
        }
      } else {
        joinButton.disabled = false;
      }
    }
  }

  if (sendPinCodeForm) {
    if (sendPinCodeForm.checkValidity()) {
      pinCodeInputs.forEach((input) => (input.disabled = false));
    }

    emailInput.addEventListener("blur", () => {
      emailInput.value = emailInput.value.trim();
      if (sendPinCodeForm.checkValidity()) {
        pinCodeInputs.forEach((input) => (input.disabled = false));
      } else {
        pinCodeInputs.forEach((input) => {
          input.disabled = true;
          input.value = "";
          clearVerifyMessage();
        });
      }
    });

    sendPinCodeForm.addEventListener("ajax:success", (event) => {
      document.querySelector(".js-send-pin-code-response-message").textContent = event.detail[0].message;
      micromodal("target-send-pin-code");
      if (event.detail[0].status === "ok") {
        pinCodeInputs.forEach((input) => {
          input.disabled = false;
          input.value = "";
          clearVerifyMessage();
        });
      }
    });

    sendPinCodeForm.addEventListener("ajax:error", () => {
      document.querySelector(".js-send-pin-code-response-message").textContent = "認証コードの送信に失敗しました。恐れ入りますが再度認証コードの送信をお試しください。";
      micromodal("target-send-pin-code");
    });

    pinCodeInputs.forEach((input, index) => {
      input.addEventListener("input", () => {
        if (input.value.length === 1) {
          if (Array.from(pinCodeInputs).every((input) => input.value.length === 1)) {
            if (sendPinCodeForm.reportValidity()) {
              document.getElementById("js-verify-pin-code-email").value = emailInput.value;
              clearVerifyMessage();
              // NOTE: grecaptcha.jsを実行するためにクリック実行
              document.querySelector(".js-verify-pin-code-button").click();
            }
          } else if (index < pinCodeInputs.length - 1) {
            pinCodeInputs[index + 1].focus();
          }
        } else {
          // NOTE: <input type=number>ではmaxlengthが効かないためjsで桁数を制限
          input.value = input.value.slice(0, 1);
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace") {
          if (input.value === "" && index > 0) {
            pinCodeInputs[index - 1].focus();
          }
          clearVerifyMessage();
        }
      });

      input.addEventListener("click", () => {
        input.select();
      });
    });

    verifyPinCodeform.addEventListener("ajax:success", (event) => {
      const data = event.detail[0];
      message.textContent = data.message;
      switch (data.status) {
        case "ok":
          message.classList.add(verifySuccessMessageClass);
          pinCodeInputs.forEach((input) => (input.disabled = true));
          emailInput.disabled = true;
          document.getElementById("js-send-pin-code-button").disabled = true;
          isVerifiedPinCode = true;
          activateJoinButton();
          break;
        case "limit_exceeded":
          message.classList.add(verifyErrorMessageClass);
          pinCodeInputs.forEach((input) => (input.disabled = true));
          break;
        default:
          message.classList.add(verifyErrorMessageClass);
          clearPinCodeInputs();
          break;
      }
    });

    verifyPinCodeform.addEventListener("ajax:error", () => {
      message.textContent = "認証に失敗しました。恐れ入りますが認証コードの再入力をお願いいたします。";
      message.classList.add(verifyErrorMessageClass);
      clearPinCodeInputs();
    });

    let joinButtonClicked = false;
    joinButton.addEventListener("click", (event) => {
      if (!sendPinCodeForm.reportValidity()) {
        event.preventDefault();
        return;
      }
      if (!verifyPinCodeform.reportValidity()) {
        event.preventDefault();
        return;
      }
      joinButtonClicked = true;
      if (validateSamePassword()) {
        document.getElementById("js-user-email").value = emailInput.value;
        document.querySelectorAll(".js-pin-code-input").forEach((input) => {
          document.getElementById(`js-user-pin-code-${input.dataset.index}`).value = input.value;
        });
      }
    });

    uidInput.addEventListener("blur", () => {
      uidInput.value = uidInput.value.trim();
      if (uidInput.value !== "") {
        activateJoinButton();
      }
    });

    if (passwordInput) {
      passwordInput.addEventListener("input", () => {
        if (joinButtonClicked) {
          validatePassword(passwordInput);
        }
      });

      passwordConfirmationInput.addEventListener("input", () => {
        if (joinButtonClicked) {
          validatePasswordConfirmation(passwordInput, passwordConfirmationInput);
        }
      });

      passwordInput.addEventListener("blur", () => {
        if (passwordInput.value !== "") {
          activateJoinButton();
        }
      });

      passwordConfirmationInput.addEventListener("blur", () => {
        if (passwordInput.value !== "") {
          activateJoinButton();
        }
      });
    }
  }
});
