import { micromodal } from "javascripts/micromodal";

document.addEventListener("DOMContentLoaded", () => {
  const userCreatedSucessModal = document.getElementById("target-user-created-success");
  const catalystProps = document.getElementById("catalyst-props");
  if (userCreatedSucessModal) {
    micromodal("target-user-created-success");
    if (catalystProps && catalystProps.dataset.prop12) {
      // eslint-disable-next-line no-undef
      catalyst_user_regist(catalystProps.dataset.prop12);
    }
  }
});
