window.onclick_exlink = function (domain, area, spot_id, spot_name, hotel_code, price, alliance, eventparam, clickid) {
  if (/tabelog/.test(domain)) {
    // eslint-disable-next-line no-undef
    onclickcatalyst_tabelog(domain, area);
  } else {
    const page_unique_name = [spot_id, spot_name].filter((v) => v);
    // eslint-disable-next-line no-undef
    onclickcatalyst_exlink(domain, area, page_unique_name.join("|"), hotel_code, price, alliance, eventparam, clickid);
  }
  // eslint-disable-next-line no-undef
  ga("send", "event", "外部リンク送客", "click", "#{domain} | #{area}");
  // eslint-disable-next-line no-undef
  gtag("event", "conversion", { send_to: "AW-945963010/0TeCCIvWi_UBEIKAicMD" });
};

window.onclick_share = function (link, area) {
  // eslint-disable-next-line no-undef
  onclickcatalyst_share(link, area);
  // eslint-disable-next-line no-undef
  ga("send", "event", "SNSシェア", "click", "#{link} | #{area}");
};

window.onclick_affclick_parallel = function (
  domain,
  area,
  spot_id,
  spot_name,
  hotel_code,
  price,
  alliance,
  clickid,
  inclusive_strikethrough,
  inclusive,
  aff_posted_price,
  eventparam,
) {
  const page_unique_name = [spot_id, spot_name].filter((v) => v);
  // eslint-disable-next-line no-undef
  onclickcatalyst_affclick_parallel(
    domain,
    area,
    page_unique_name.join("|"),
    hotel_code,
    price,
    alliance,
    clickid,
    inclusive_strikethrough,
    inclusive,
    aff_posted_price,
    eventparam,
  );
};
