/* global grecaptcha */
import Rails from "@rails/ujs";

document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.querySelectorAll("[data-google-recaptcha-action]")).forEach((node) => {
    const action = node.dataset.googleRecaptchaAction;
    const sitekey = node.dataset.googleRecaptchaSitekey;
    const form = node.closest("form");

    form.addEventListener(
      "submit",
      function (event) {
        if (!form.reportValidity()) {
          return;
        }
        grecaptcha.ready(() => {
          try {
            grecaptcha.execute(sitekey, { action: action }).then(
              (token) => {
                node.value = token;
                this.submit();
              },
              () => {
                alert("reCAPTCHAによる検証が行えませんでした");
              },
            );
          } catch (e) {
            alert("reCAPTCHAによる検証が行えませんでした");
          }
        });
        event.preventDefault();
      },
      false,
    );
  });

  Array.from(document.querySelectorAll("[data-google-recaptcha-action-ajax]")).forEach((node) => {
    const action = node.dataset.googleRecaptchaActionAjax;
    const sitekey = node.dataset.googleRecaptchaSitekey;
    const form = node.closest("form");

    form.querySelector("[type='submit']").addEventListener(
      "click",
      function (event) {
        if (!form.reportValidity()) {
          return;
        }
        grecaptcha.ready(() => {
          try {
            grecaptcha.execute(sitekey, { action: action }).then(
              (token) => {
                node.value = token;
                Rails.fire(form, "submit");
              },
              () => {
                alert("reCAPTCHAによる検証が行えませんでした");
              },
            );
          } catch (e) {
            alert("reCAPTCHAによる検証が行えませんでした");
          }
        });
        event.preventDefault();
      },
      false,
    );
  });
});
