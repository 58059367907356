document.addEventListener("DOMContentLoaded", () => {
  const MAX_ADULT_COUNT = 14;
  const MAX_CHILD_COUNT = 6;
  const MAX_AGE = 17;
  const DEFAULT_ADULT = 2;
  const searchConditionsForm = document.querySelector(".js-search-conditions-form");
  const personInputs = document.querySelectorAll(".js-persons[id^='person']");
  const childAgeInputs = document.querySelectorAll(".js-child-age");
  const childInputContainer = document.querySelector(".js-child-input-container");
  const personAdultInput = document.getElementById("person-adult");
  let adultCount = DEFAULT_ADULT;
  if (personAdultInput) {
    adultCount = parseInt(personAdultInput.value);
  }
  let childCount = childAgeInputs.length;

  function handleAdjustGuests(input, increment) {
    updateGuests(input, increment);
    updateAdjustGuestsButtonsState();
    outputGuests();
  }

  function updateGuests(input, increment) {
    const currentValue = parseInt(input.value);
    const newValue = increment ? currentValue + 1 : currentValue - 1;
    if (newValue < 0 || !input.id.startsWith("person")) {
      return;
    }
    const isChild = input.id === "person-child";
    if (newValue <= (isChild ? MAX_CHILD_COUNT : MAX_ADULT_COUNT)) {
      input.setAttribute("value", newValue);
      if (isChild) {
        increment ? addChildInput() : removeChildInput();
      } else {
        adultCount = newValue;
      }
    }
  }

  function outputGuests() {
    if (searchConditionsForm && searchConditionsForm.dataset.realtimeSearch === "false") {
      document.querySelectorAll(".js-persons-output").forEach((outputElement) => {
        outputElement.textContent = `大人${adultCount}名${childCount > 0 ? `、子供${childCount}名` : ""} / 1室`;
      });
    }
  }

  function updateAdjustGuestsButtonsState() {
    personInputs.forEach((input) => {
      const decrementButton = input.closest(".c-persons__content").querySelector("[data-persons='decrement']");
      const incrementButton = input.closest(".c-persons__content").querySelector("[data-persons='increment']");
      updateButtonDisabled(decrementButton, input.value === "0");
      if (input.classList.contains("js-adult")) {
        updateButtonDisabled(decrementButton, adultCount <= 1);
        updateButtonDisabled(incrementButton, adultCount === MAX_ADULT_COUNT);
      } else {
        updateButtonDisabled(incrementButton, childCount === MAX_CHILD_COUNT);
      }
    });
  }

  function updateButtonDisabled(button, condition) {
    if (condition) {
      button.setAttribute("disabled", true);
    } else {
      button.removeAttribute("disabled");
    }
  }

  function addChildInput() {
    childCount++;
    const childInput = document.createElement("section");
    childInput.classList.add(`js-persons-add-${childCount}`);

    const template = document.querySelector(".js-child-age-input-template").innerHTML;
    let newFieldHtml = template.replace(/NEW_INDEX/g, childCount);
    if (childCount == 0) {
      newFieldHtml = '<p class="c-persons__heading--add">お子様の年齢を入力してください</p>' + newFieldHtml;
    }
    childInput.insertAdjacentHTML("beforeend", newFieldHtml);
    childInputContainer.appendChild(childInput);
    childInputContainer.classList.add("c-persons__container--add");
    childInputBindListener(childInput.querySelector(".js-persons"));
  }

  function removeChildInput() {
    if (childCount > 0) {
      const lastChildInput = document.querySelector(`.js-persons-add-${childCount}`);
      lastChildInput.remove();
      childCount--;
    }
    if (childCount === 0) {
      const addHeading = childInputContainer.querySelector(".c-persons__heading--add");
      if (addHeading) {
        addHeading.remove();
      }
      childInputContainer.classList.remove("c-persons__container--add");
    }
  }

  function childInputBindListener(input) {
    const decrement = input.closest(".c-persons__content").querySelector("[data-persons='decrement']");
    const increment = input.closest(".c-persons__content").querySelector("[data-persons='increment']");
    decrement.addEventListener("click", () => {
      let currentValue = parseInt(input.value);
      currentValue > 0 ? (input.value = currentValue - 1) : 0;
      if (input.id === "person-child") {
        removeChildInput();
      }
    });
    increment.addEventListener("click", () => {
      let currentValue = parseInt(input.value);
      currentValue < MAX_AGE ? (input.value = currentValue + 1) : MAX_AGE;
      if (input.id === "person-child" && currentValue < MAX_AGE) {
        addChildInput();
      }
    });
  }

  if (personInputs[0]) {
    personInputs.forEach((input) => {
      const decrement = input.closest(".c-persons__content").querySelector("[data-persons='decrement']");
      const increment = input.closest(".c-persons__content").querySelector("[data-persons='increment']");
      decrement.addEventListener("click", () => handleAdjustGuests(input, false));
      increment.addEventListener("click", () => handleAdjustGuests(input, true));
    });
  }

  if (childAgeInputs[0]) {
    childAgeInputs.forEach((input) => {
      childInputBindListener(input);
    });
  }
  outputGuests();
});
